(function () {
  'use strict';

  class EditClassroomsCtrl {
    constructor($mdDialog, $rootScope, Classrooms, EditClassrooms, ModuleSet, User, Schools, Utils) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.Classrooms = Classrooms;
      vm.ModuleSet = ModuleSet;
      vm.User = User;
      vm.Schools = Schools;
      vm.currentUser = $rootScope.user;
      vm.isTeacher = Utils.roleCheck(['Teacher']);
      vm.classroom = angular.copy(EditClassrooms.getObj().classrooms);
      vm.init();
    }
    init() {
      const vm = this;
      angular.forEach(vm.classroom.module_sets, function (v, k, o) {
        o[k] = v.id;
      });
      vm.classroom.school_id = vm.isTeacher ? vm.currentUser.school_id : vm.classroom.school.id;
      vm.classroom.teacher_id = vm.isTeacher ? vm.currentUser.id : vm.classroom.teacher.id;
      vm.moduleSetIndex();
      vm.getTeachers();
      vm.getSchools();
    }
    getTeachers() {
      const vm = this;
      vm.User.getTeachers()
        .then((data)=> {
          vm.teachersIndex = data;
        });
    }
    getSchools() {
      const vm = this;
      vm.Schools.schoolsIndex()
        .then((data)=> {
          vm.schoolsIndex = data;
        });
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    submit(form) {
      let vm = this,
          classroom = angular.copy(vm.classroom);
      if (form.$valid) {
        angular.forEach(classroom.module_sets, function (v, k, o) {
          o[k] = {id: v};
        });
        vm.Classrooms.classroomEdit(classroom)
          .then((data)=> {
            vm.hide(data);
          });
      }
    }
    moduleSetIndex() {
      const vm = this;
      vm.ModuleSet.moduleSetIndex()
        .then((data) => {
          vm.ModuleSetsPayload = data;
        });
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:EditClassroomsCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('EditClassroomsCtrl', EditClassroomsCtrl);
}());
